@import "_variables.scss";

.search{
    .bookshelf{
        width: 100%;
        height: auto;

        &.bookshelf-top{
            margin-top: 20px;
        }
        &.bookshelf-bottom{
            margin-top: 30px;
        }
    }
}

.featured-by-logos {
    display: none;
}

.recommended-books{
    margin-top: 40px;
}

@media (min-width: $md){
    .search{
        .bookshelf{
            &.bookshelf-top{
                margin-top: 90px;
            }
            &.bookshelf-bottom{
                margin-top: 70px;
            }
        }
    }

    .featured-by-logos {
        display: block;
    }
    .popular-books, .recommended-books{
        margin-top: 96px;
    }
}
