@import "_variables.scss";

$skew-angle: -5deg;
$skew-angle-2: 10deg;
$slant-top: 600px;
$sidebar-width: 350px;


.error {
    color: $secondary;
    text-align: center;
    color: black;
    padding: 0px 20px;
    
    .error-code{
        font-weight: 700;
        font-size: 2rem;
        margin-top: 7rem;
    }

    .error-code-message {
        margin-top: 3rem;
    }

    .report-book-alert{
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 1rem;
    .alert{
       width: fit-content;
    }
}
    .report-book-button {
        margin-top: 0.5rem;
        max-width: 12rem;
    }

    #bottom-slant, #bottom-slant-2 {
        height: 100%;
        background: $bg-secondary;
        width: 100%;
        position: absolute;
    }
    #bottom-slant {
        top: $slant-top;
        transform: skewY($skew-angle);
    }
    #bottom-slant-2{
        top: $slant-top + 60px;
        transform: skewY($skew-angle-2);
    }

 

    @media (min-width: $md) {
        .error-code{
            font-size: 3rem;
        }
    }
}

.error-image {
    img {
        margin-top: 4rem;
        height: 300px;
        z-index: 2;
    }
}

@media (max-width: $sm){
    .error-image {

        img {
            margin-top: 2rem;
            height: 200px;
        }
    }
}