// @font-face {
//     font-family: "Averta";
// 	font-weight: 100;
//     src: url("../fonts/Averta/Averta-Extrathin.woff");
// }
// @font-face {
//     font-family: "Averta";
// 	font-weight: 200;
//     src: url("../fonts/Averta/Averta-Thin.woff");
// }
// @font-face {
//     font-family: "Averta";
// 	font-weight: 300;
//     src: url("../fonts/Averta/Averta-Light.woff");
// }
@font-face {
    font-family: "Averta";
	font-weight: 400;
    src: url("../fonts/Averta/Averta.woff");
}
// @font-face {
//     font-family: "Averta";
// 	font-weight: 500;
//     src: url("../fonts/Averta/Averta-Semibold.woff");
// }
@font-face {
    font-family: "Averta";
	font-weight: 600;
    src: url("../fonts/Averta/Averta-Semibold.woff");
}
@font-face {
    font-family: "Averta";
	font-weight: 700;
    src: url("../fonts/Averta/Averta-Bold.woff");
}
// @font-face {
//     font-family: "Averta";
// 	font-weight: 800;
//     src: url("../fonts/Averta/Averta-Extra-Bold.woff");
// }
// @font-face {
//     font-family: "Averta";
// 	font-weight: 900;
//     src: url("../fonts/Averta/Averta-Black.woff");
// }
