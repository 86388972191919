@import "_variables.scss";

$skew-angle: -5deg;
$skew-angle-2: 10deg;
$slant-top: 600px;
$sidebar-width: 350px;

.account {
    position: relative;
    overflow: hidden;
    flex-direction: column;

    #m-book-stack {
        right: -10px;
        height: 150px;
        position: absolute;
    }

    #book-stack {
        position: absolute;
        top: $slant-top - 250px;
        left: 200px;
        width: 300px;
    }

    #bottom-slant, #bottom-slant-2 {
        height: 100%;
        background: $bg-secondary;
        width: 100%;
        position: absolute;
    }
    #bottom-slant {
        top: $slant-top;
        transform: skewY($skew-angle);
    }
    #bottom-slant-2{
        top: $slant-top + 60px;
        transform: skewY($skew-angle-2);
    }

    #account-wrapper-bg {
        height: 100%;
        width: 100%;
        position: absolute;
        background: $bg-secondary;
        transform: translateY(120px);
        z-index: 1;
    }

    .account-nav {
        padding: 20px;
        margin-bottom: 40px;
        h2 {
            font-size: 20px;
        }

        .dropdown-menu {
            width: 100%;
        }
    }

    #account-nav-toggle {
        width: 100%;
        background: $bg-tertiary!important;
        color: $gray!important;
        text-align: left;
        position: relative;
        border-radius: $input-border-radius;
    }

    #caret-wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 50px;
        background: $bg-secondary;
        border-radius: 0 ($input-border-radius - 2) ($input-border-radius - 2) 0;
        padding: 8px 6px 6px 6px;
        text-align: center;
        color: $tertiary;
    }

    .nested-dropdown {
        padding-left: 40px;
    }

    .dropdown-menu .dropdown-item{
        color: $gray;
        &.active, &:active {
            color: $secondary;
            border-left: 2px solid $bg-secondary;
        }
    }


    .shelf-row {
        margin-bottom: 25px;
    }

    $view-more-row-bottom: 50px;
    .view-more-row {
        margin-bottom: $view-more-row-bottom;
    }
    #shelves-wrapper {
        margin-bottom: -$view-more-row-bottom;
    }

    #bookshelf-subtitle {
        font-size: 24px;
        color: $secondary;
        font-weight: 600;
    }

    .sidebar {
        z-index: 2;
        padding: 30px;
        height: 100%;
        width: $sidebar-width;
        h2 {
            font-size: 36px;
            margin-bottom: 24px;
        }
        ul#mainlist {
            padding: 0px;
            list-style: none;

            li {
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 12px;
                a.main-active {
                    color: $secondary;
                }
            }
        }

        ul#sublist {
            padding: 0px;
            list-style: none;
            li {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 12px;
                a.sub-active {
                    color: $secondary;
                }
            }
        }
    }

    .wrapper {
        position: relative;
        z-index: 1;
        padding: 30px 45px;
        margin-bottom: 30px;
        border-radius: $border-radius;
    }

    .content {
        height: 100%;
        width: 100%;
        .content-title {
            margin: 20px 30px;
        }
        #user-wpm {
            font-size: 30px;
            color: $secondary;
            margin-bottom: 5px;
        }
    }

    .shelves-row {
        margin: 0;
    }

    .form-title {
        font-size: 20px;
        margin-top: 32px;

        &.form-title-first {
            margin-top: 0px;
        }
    }

    .reading-speed-display{ 
        margin-bottom: 50px;
    }

    @media (min-width: $sm) {
        .wrapper {
            border-radius: 20px;
        }
    }

    @media (min-width: $md) {
        flex-direction: row;
        .wrapper {
            padding: 50px 45px;
        }
        .content {
            width: calc(100% - #{$sidebar-width});
            padding: 50px;

            .content-title {
                margin-left: 0px;
                margin-right: 0px;
            }
            #user-wpm {
                font-size: 36px;
            }
        }

        .shelves-row {
            margin-bottom: 60px;
        }

        .form-title {
            font-size: 36px;
            margin-top: 70px;
        }
    }
}
