@import "_variables.scss";

.info {
    position: relative;
    overflow-y: hidden;
    h1{
        font-weight: 700;
    }

    .info-section {
        $desc-skew: 3deg;

        position: relative;
        color: $quatenary;
        transform: skewY(-$desc-skew);
        background: $bg-secondary;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-top: -50px;
        margin-bottom: -40px;
        .info-content{
            transform: skewY($desc-skew);
            z-index: 5;
        }
        .description{
            margin-bottom: 40px;
        }
    }
    
    .team-member {
        text-align: center;
    }

    .team-member-img{
        height: 128px;
        width: 128px;
        margin-bottom: 10px;
    }

    h3{
        font-size: 16px;
        color: $tertiary;
        margin-bottom: 56px;
    }

    .woman-reading{
        position: relative;
        z-index: 1;
    }

    #woman-reading-m{
        #woman-reading-m-img{
            height: 100vw;
            max-height: 300px;
            transform: translateX(-10%);
        }
    }

    #woman-reading{
        width: 100%;
        position: absolute;
        overflow: hidden;
        #woman-reading-img {
            height: auto;
            max-height: 600px;
            width: 80%;
        }
    }

    #woman-spacer {
        height: 45vw;
        width: 100%;
        display: block;
        max-height: 600px;
    }

    footer {
        position: relative;
        z-index: 10;
    }

    @media(min-width: $md){
        .navbar {
            z-index: 5;
        }
        .info-section {
            $desc-skew: -2deg;
            transform: skewY(-$desc-skew) translateY(calc(80px + 2vw));
            padding-top: 80px;
            padding-bottom: 80px;
            margin-bottom: -40px;
            z-index: 1;
            .info-content{
                transform: skewY($desc-skew);
            }
            .description{
                margin-bottom: 40px;
            }

            #top-left-square{
                position: absolute;
                z-index: -1;
                height: 240px;
                width: 80px;
                transform: skewX(-4deg) translate(-20px, -200px);
                background: $bg-primary;
            }

            #bottom-left-square {
                position: absolute;
                z-index: -1;
                bottom: 0px;
                height: 100px;
                width: 100px;
                transform: skewY(4deg) translate(-20px, 50px);
                background: $bg-secondary;
            }
        }

        .team-member-img{
            height: 128px;
            width: 128px;
            margin-bottom: 10px;
        }

        h3{
            font-size: 16px;
            color: $tertiary;
            margin-bottom: 56px;
        }

        #bottom-slant {
            position: absolute;
            width: 100vw;
            height: calc(200px + 2vw);
            transform: skewY(-6deg) translateY(100px);
            background: $bg-secondary;
            bottom: 0px;
            z-index: 0;
        }
    }
}
