@import "_variables.scss";

.info {
    .about-content-wrapper {
        $padding-left: 20px;
        position: relative;
        width: 100%;
        background-color: $bg-secondary;
        padding: 28px 0px 50px;
    }

    .info .featured-by-logos {
        margin-bottom: 100px;
    }
}
