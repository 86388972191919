@import "_variables.scss";

$padding-left: 100px;
$wrapper-padding-left: 30px;
$skew-angle: 5deg;

.formpage {
    position: relative;
    overflow: hidden;
}

.formpage-content-wrapper {
    position: relative;
    min-height: calc(100vh - #{$navbar-plus-footer-height - 15px});
    margin: auto;
}

.formpage-content {
    max-width: $xl;
    margin: auto;
    position: relative;
}

.formpage-form-wrapper{
    padding: $wrapper-padding-left 0px;
}

.formpage-description{
    color: $quatenary;
}

.formpage-desc-title{
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: 600;
}

.formpage-form-title {
    font-weight: 600;
}

.btn {
    width: 100%;
}

.formpage {
    #formpage-img {
        width: 500px;
        position: absolute;
        left: -50px;
        top: 50px;
        img {
            width: 100%;
        }
    }

    #formpage-img-m {
        height: 200px;
        margin: auto;
        img {
            height: 100%;
        }
    }

    #bottom-slant {
        height: 100%;
        background: $bg-secondary;
        width: 100%;
        position: absolute;
        top: 250px;
        transform: skewY($skew-angle);
    }

    @media (min-width: $sm){
        #bottom-slant {
            top: 300px;
        }

        #formpage-img-m {
            height: 250px;
            img {
                height: 100%;
            }
        }
    }

    @media (min-width: $md) {
        label {
            font-weight: 500;
            font-size: 20px;
            color: $primary;
        }
    }
    @media (max-width: $lg) {
        #bottom-slant {
            top: 800px;
        }
    }
}


.separator {
    align-items: center;
    text-align: center;
    margin: 10px 0px;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid #000;
}
.separator::before {
    margin-right: 10px;
}
.separator::after {
    margin-left: 10px;
}

@media (min-width: $lg){
    .formpage #bottom-slant {
        top: 400px;
    }
    .formpage-description{
        margin-top: 450px;
        .container{
            padding: 40px $wrapper-padding-left 0px;
        }
    }
    .formpage-content-wrapper{
        padding: 45px $padding-left;
    }
    .formpage-form-wrapper{
        padding: 80px $wrapper-padding-left;
    }
    .formpage-desc-title {
        font-size: 2.5rem;
    }
    .formpage-desc-text {
        font-size: 18px;
    }
    .formpage-form-title {
        font-size: 2.5rem;
    }
}
