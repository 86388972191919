@import "_variables.scss";

$xs-pc: 45%;
$sm-pc: 30%;
$md-pc: 20%;

$width: 200px;

.collection-row {
    flex-wrap: wrap;
    justify-content: center;
}

.item-container{
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    flex: 0 0 $width;
    width: $width;
    margin-bottom: 30px;
}

.collection-subtitle {
    margin-top: 8px;
    font-weight: 600;
    margin-bottom: 0px;
}

// @media (min-width: $sm){
//     .item-container{
//         flex: 0 0 $sm-pc;
//         max-width: $sm-pc;
//     }
// }
//
// @media (min-width: $md){
//     .item-container{
//         flex: 0 0 $md-pc;
//         max-width: $md-pc;
//     }
// }
