@import "_variables.scss";

.test-subtitle{
    font-weight: 600;
}

.test-row {
    margin-bottom: 15px;
}

.test-text {
    white-space: pre-wrap;
}