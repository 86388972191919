$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$nav-icon-bar-colour: black;

$transition: 0.3s ease all;

$default-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);

$primary: black;
$secondary: #c34b30;
$tertiary: white;
$quatenary: #f5e2bf;
$gray: #7f7f7f;

$primary-hover: $secondary;
$secondary-hover: #943924;

$bg-primary: #f5e2bf;
$bg-secondary: #c34b30;
$bg-tertiary: white;

$btn-primary-text: white;
$btn-primary-bg: #c34b30;
$btn-secondary-text: white;
$btn-secondary-bg: #943924;
$btn-dark-text: white;
$btn-dark-bg: #a27749;

$input-bg: white;
$input-border-color: #e3e3e3;
$input-border: 1px solid $input-border-color;
$input-box-shadow: $default-box-shadow;
$input-border-radius: 8px;

$navbar-plus-footer-height: 145px;

$border-radius: 8px;
