@import "_variables.scss";
@import "_fonts.scss";

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: $bg-primary;
  font-family: "Averta", "Muli", sans-serif;
}
.main {
  flex: 1 0 auto;
  background: $bg-primary;
  line-height: 1.3;
}
.modal-dialog {
  font-family: "Averta", "Muli", sans-serif;
  h5 {
    font-weight: 400;
  }
}
.v-center {
  display: flex;
  // justify-content: center;
  align-items: center;
}
.vh-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: $md - 0.02) {
  .desktop {
    display: none;
  }
}

@media (min-width: $md) {
  .mobile {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
}

b {
  font-weight: 700;
}

a,
.link {
  color: $primary;
  transition: $transition;
  text-decoration: none;
  &:focus,
  &:hover,
  &:active {
    color: $primary-hover;
    text-decoration: none;
    cursor: pointer;
  }
  &.underline {
    text-decoration: underline !important;
  }
  &.secondary {
    color: $secondary;
    &:focus,
    &:hover {
      color: $secondary-hover;
    }
  }
  &.tertiary:focus,
  &.tertiary:hover {
    color: $tertiary;
  }
}

.small-link {
  text-decoration: underline !important;
  &:focus,
  &:hover {
    color: $primary-hover;
    text-decoration: none;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
}

.form-group {
  margin-bottom: 24px;
}

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

$input-height: 50px;
$input-mobile-height: 40px;

input,
input.form-control,
textarea,
textarea.form-control {
  background-color: $input-bg;
  border: $input-border;
  &:focus {
    box-shadow: none;
    outline: 0;
    border: $input-border;
    border-color: $input-border-color;
  }
}

input,
input.form-control {
  height: $input-mobile-height;
  font-size: 12px;
  padding-top: 10px;
}

@media (min-width: $md) {
  input,
  input.form-control {
    height: $input-height;
    font-size: 16px;
    padding-top: 8px;
  }
}

.input-group {
  .input-group-prepend .input-group-text {
    background-color: $input-bg;
  }

  &.search-input-group,
  &.search-input-group.search-small {
    box-shadow: $input-box-shadow;
    border-radius: $input-border-radius;
    height: $input-mobile-height;
    input.form-control {
      height: $input-mobile-height;
    }
    .input-group-prepend .input-group-text {
      border-right: none;
      border-radius: $input-border-radius 0px 0px $input-border-radius;
      border: none;
    }
    .input-group-append .btn {
      border-radius: 0px $input-border-radius $input-border-radius 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 12px;
    }
    input.form-control {
      border-left: none;
      border-right: none;
      font-size: 16px;
      padding-top: 9px;
      padding-left: 0;
    }
    .search-icon-wrapper {
      border: $input-border;
      border-color: $input-border-color;
      border-radius: $input-border-radius;
      border-right: none;
    }
  }

  @media (min-width: $md) {
    &.search-input-group {
      font-size: 16px;
      height: $input-height;
      input.form-control {
        height: $input-height;
        font-size: 16px;
      }
      .input-group-append .btn {
        border-radius: 0px $input-border-radius $input-border-radius 0px;
        font-size: 16px;
        padding-left: 33px;
        padding-right: 33px;
      }
    }
  }
}

.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  form {
    width: 100%;
  }
}

.btn {
  font-size: 16px;
  padding: 9px 20px;
  .svg-inline--fa {
    margin: 0px 8px;
  }
  &.btn-primary {
    color: $btn-primary-text !important;
    background-color: $btn-primary-bg !important;
    border: 1px solid $btn-primary-bg;
    box-shadow: 0 0 6px 0 $btn-primary-bg;
  }
  &.btn-secondary {
    color: $btn-secondary-text !important;
    background-color: $btn-secondary-bg !important;
    border: 1px solid $btn-secondary-bg;
    box-shadow: 0 0 6px 0 $btn-secondary-bg;
  }
  &.btn-dark {
    color: $btn-dark-text !important;
    background-color: $btn-dark-bg !important;
    border: 1px solid $btn-dark-bg;
    box-shadow: 0 0 6px 0 $btn-dark-bg;
  }
  &.btn-outline-primary {
    color: $btn-primary-bg !important;
    border: solid 3px $btn-primary-bg !important;
    &:hover {
      color: $btn-primary-text !important;
      background-color: $btn-primary-bg !important;
    }
  }

  &.btn-small {
    padding-top: 7px;
    padding-bottom: 7px;
    width: auto;
  }
}

.text-primary {
  color: $secondary !important;
}

.text-secondary {
  color: $quatenary !important;
}

.valid-feedback,
.invalid-feedback {
  margin-top: 10px;
}

.logos-primary {
  fill: $bg-primary;
}

.logos-gray {
  fill: #c1c1c1;
}

.amazon-affiliate-desc {
  font-style: italic;
  margin-bottom: 24px;
}

.wrapper {
  background: $bg-tertiary;
  border-radius: 20px;
  box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.13);
}

@media (min-width: $sm) {
  .wrapper {
    border-radius: 8px;
  }
}

@media (min-width: $md) {
  .subtitle {
    font-size: 24px;
  }
  .main {
    line-height: 1.5;
  }
  .btn {
    padding: 14px 24px;
  }
}

.dropdown-menu {
  border-radius: $input-border-radius;
  box-shadow: $default-box-shadow;
  .dropdown-item {
    &.active,
    &:active {
      color: $secondary;
      background-color: $bg-tertiary;
      font-weight: 600;
    }
  }
}

.shelf-dropdown {
  height: 100%;
  #create-shelf-icon {
    margin-right: 8px;
  }
  .btn {
    box-shadow: none;
    display: flex;
    align-items: center;
    max-width: 100%; 
    height: 100%;
    overflow: hidden;
  }
  .dropdown-menu {
    border-radius: 4px;
    width: 100%;
    .dropdown-item {
      color: $gray;
      &.active {
        border-left: 2px solid $bg-secondary;
        color: $secondary;
      }
    }
  }
   .button-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
  
}


//pagination theme color
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #c34b30;
  border-color: #c34b30;
}

.page-link,
.page-link:hover {
  color: #c34b30;
}

.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgb(195 75 48 / 25%);
}
