@import "_variables.scss";

.main {
    position: relative;
}

.search {
    display: flex;
    flex-direction: column;
    justify-content: center;

    #woman-map {
        position: absolute;
        right: -50px;
        height: 150px;
        top: 40px;
    }

    &.search-results {
        min-height: 180px;
    }

    .search-content {
        margin-top: 50px;
        z-index: 1;
        h1, h2 {
            font-size: 20px;
            font-weight: 600;
        }
    }
}

.search-content-wrapper{
    $padding-left: 20px;
    position: relative;
    width: 100%;
    background-color: $bg-secondary;
    padding: 28px 0px 50px;
    display: flex;
    flex-direction: column;

    @media (min-width: $md){
        padding: 64px $padding-left 80px;
    }

    .search-inner-wrapper{
        position: relative;
        background-color: $bg-tertiary;
        box-shadow: $default-box-shadow;
        border-radius: 8px;
        padding: 32px 40px;

        h2{
            font-size: 24px;
        }
    }

    #accent{
        position: absolute;
        top: 50%;
        transform: translate(-$padding-left, -50%) skewY(5deg);
        height: 150px;
        width: 100%;
        background: $bg-primary;
    }
}

@media (min-width: $md){
    .search{
        &.search-results {
            height: 350px;
            .search-content {
                margin-top: 160px;
            }
        }
        #woman-map {
            height: 350px;
            right: 0px;
        }
        .search-content {
            margin-top: 47px;
            h1, h2 {
                font-size: 34px;
            }
        }
    }
}