@import "_variables.scss";

.navbar{
    background: transparent;
    z-index: 5;

    .nav-logo{
        margin-right: 10px;
        width: 34px;
    }

    .navbar-brand{
        font-size: 16px;
        font-weight: 600;

        @media (min-width: $md){
            font-size: 24px;
        }
    }

    #nav-content {
        width: 100%;
        flex-grow: 1;
        justify-content: flex-end;

        @media (min-width: $md){
            .search-container {
                padding-left: 40px;
                max-width: 600px;
            }
        }
            @media (max-width: $sm){
                .search-container {
                    padding: 8px;
                }

            }
        
    }


    .nav-item{
        font-size: 16px;
        padding-left: 40px;
        font-weight: 600;
        .nav-link {
            color: $primary;
            transition: $transition;
            padding-top: .75rem;
            padding-bottom: .25rem;

            &:hover{
                color: $secondary;
            }
        }
    }

    .navbar-toggler{
        outline: 0;
        border: none;

        .icon-bar{
            display: block;
            width: 22px;
            height: 2px;
            border-radius: 1px;
            transition: all 0.2s;
            background-color: $nav-icon-bar-colour;

            &.top-bar{
                transform: rotate(0);
                &.collapsed{
                    transform: rotate(45deg);
                    transform-origin: 10% 10%;
                }
            }
            &.middle-bar{
                margin-top: 4px;
                &.collapsed{
                    opacity: 0;
                }
            }
            &.bottom-bar{
                margin-top: 4px;
                transform: rotate(0);
                &.collapsed{
                    transform: rotate(-45deg);
                    transform-origin: 10% 90%;
                }
            }
        }
    }

    .dropdown-toggle-btn{
        display: flex;
        align-items: center;
    }

    .nav-account-img{
        height: 20px;
        width: 20px;
        display: inline-block;
        margin-right: 4px;
    }

    .nav-account-chevron {
        margin-left: 4px;
    }
}
