@import "_variables.scss";

.formpage {
    .login-btns .btn {
        margin-bottom: 10px;
    }

    @media (min-width: $md){
        .login-btns .btn {
            margin-bottom: 0px;
        }
    }

    #reset-password-link {
        padding: 15px;
        margin: auto;
    }
}
