@import "_variables.scss";

#sticky-footer {
    flex-shrink: none;
    background-color: #923824;
    color: $tertiary;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
    z-index: 10;
    .row {
        margin: 0px;
        margin-top: 5px;
    }
    #cr-msg{
        margin-right: 24px;
    }
    #footer-text{
        text-align: right;
    }
    @media (max-width: #{$md - 0.02}){
        #footer-credits, #footer-text-hltr{
            display: none;
        }
        #footer-text{
            text-align: center;
        }
    }
    .footer-icon {
        margin: 0px 8px;
    }
    a {
        color: $tertiary;
        text-decoration: underline;
    }
}
