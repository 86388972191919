@import "_variables.scss";

$padding-left: 100px;
$wrapper-padding-left: 30px;
$skew-angle: -5deg;

.contact {
    &.formpage #bottom-slant {
        transform: skewY($skew-angle);
    }

    #man-on-laptop {
        width: 600px;
        position: absolute;
        right: 50px;
        top: -30px;
        img {
            width: 100%;
        }
    }

    #man-on-laptop-m {
        width: 300px;
        margin: auto;
        img {
            width: 100%;
        }
    }

    @media (min-width: $sm){
        #man-on-laptop-m {
            width: 400px;
            img {
                width: 100%;
            }
        }
    }

    @media (min-width: $lg){
        &.formpage #bottom-slant {
            top: 550px;
        }

        .formpage-description{
            margin-top: 500px;
        }
    }
}
