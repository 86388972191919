@import "_variables.scss";

$book-img-border-radius: 4px;
$selected-bg-color: #000000;
$selected-circle-color: green;
$selected-circle-size: 50px;

.BookImage{
    height: 100%;
    &.book-img-container{
        width: 100%;
    }
    .book-img-inner-container{
        width: 100%;
        max-height: 100%;
        position: relative;
    }
    a {
        width: 100%;
        height: 100%;
        &:hover {
            text-decoration: none;
        }
    }
    .book-img {
        border-radius: $book-img-border-radius;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.62);
        &.book-img-generated{
            height: 100%;
            background: $bg-primary;
            text-align: center;
            padding: 25px 5px 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            overflow: hidden;
            
            p{
                margin-bottom: 0px;
            }

            .book-img-title {
                text-decoration: none;
                font-weight: 600;
                line-height: 1.2;
                max-height: 3.6em; 
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                width: 100%;
                text-align: center;
                margin: 0 0 5px 0;
            }
            
            .book-stack {
                margin-bottom: -27px;
            }

            @media (min-width: $sm) {
                max-height: 300px;
                .book-stack {
                    margin-bottom: -70px;
                }
            }

            @media (min-width: $md) {
                max-height: 350px;
            }
            @media (min-width: $lg) {
                max-height: initial;
                .book-stack {
                    margin-bottom: -45px;
                }
            }
            @media (min-width: $xl) {
                .book-stack {
                    margin-bottom: -27px;
                }
            }
        }
    }

    .book-img-cover{
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #{$selected-bg-color}00;
        transition: $transition;
        border-radius: $book-img-border-radius;

        .checkmark-circle {
            width: $selected-circle-size;
            height: $selected-circle-size;
            border-radius: 50%;
            background-color: $selected-circle-color;
            opacity: 0;
            transition: $transition;
        }
        .checkmark {
            font-size: $selected-circle-size / 2;
        }

        &.selected{
            background-color: #{$selected-bg-color}44;
            .checkmark-circle {
                opacity: 1;
            }
        }
    }
}
