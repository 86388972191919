@import "_variables.scss";

$skew-angle: 5deg;

.formpage {
    .goodreads-btn {
        margin-bottom: 10px;
    }

    .signup-alternative-text {
        text-align: center;
    }

    #checkbox-row {
        margin: 0px;
        margin-bottom: 24px;
        position: relative;
        .form-group{
            position: absolute;
        }
        input[type='checkbox'] {
            left: 20px;
            transform: translateY(-20px);
        }
        .invalid-feedback {
            transform: translateY(100%);
        }

        #checkbox-text{
            margin-left: 30px;
        }
    }

    @media (min-width: $sm){
        #bottom-slant {
            top: 300px;
        }
    }

    @media (max-width: $lg) {
        #bottom-slant {
            top: 800px;
        }
    }
}
