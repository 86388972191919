@import "_variables.scss";

$xs-pc: 45%;
$sm-pc: 30%;
$md-pc: 20%;

.book-carousel {
    &,
    &-container {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }
  
.book-container{
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    flex: 0 0 $xs-pc;
    max-width: $xs-pc;
    width: auto;
}

@media (min-width: $sm){
    .book-container{
        flex: 0 0 $sm-pc;
        max-width: $sm-pc;
    }
}

@media (min-width: $md){
    .book-carousel {
        justify-content: center;
    }
    .book-container{
        flex: 0 0 $md-pc;
        max-width: $md-pc;
    }
}
