@import "_variables.scss";

$padding-left: 100px;

.book-details-content{
    background: $bg-tertiary;
    border-radius: 8px;
    box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.13);
    padding: 30px;
    position: relative;

    h1{
        font-size: 24px;
        margin-bottom: 2px;
        text-align: center;
        color: $primary;
    }
    .author-text{
        font-size: 18px;
        color: #888;
        margin-bottom: 16px;
        text-align: center;
    }
    .cover-container{
        margin-bottom: 19px;
        height: fit-content;
        .BookImage {
            display: flex;
            align-items: center;
            display: flex;
            a{
                min-height: 250px;
                max-height: 400px;
                height: 100%;
                
                .book-img-title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 8; /* number of lines to show */
                            line-clamp: 8; 
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
    .BookImage .book-img {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.62);
        border-radius: 10px;
    }

    h2, h3 {
        font-size: 20px;
        color: $primary;
    }

    .data-text {
        font-size: 15px;
        margin-top: 20px;
        margin-bottom: 0px;
    }
    .goodreads-logo{
        height: 50px;
    }

}

hr.similar-books-divider {
    border: solid 2px #dddddd;
    margin-bottom: 57px;
}

.similar-books {
    h2 {
        font-size: 24px;
        margin-bottom: 32px;
    }
}

@media(max-width: $sm - 0.02){
    .cover-container{
        margin: 0 20%;
    }
}

@media (min-width: $lg){
    .book-details-content-wrapper {
        padding: 45px $padding-left;
        position: relative;
        overflow: hidden;
    }
    $skew: -8deg;
    .background {
        position: absolute;
        transform: skewY($skew) translate(-$padding-left, 10%);
        background: $bg-secondary;
        width: 100%;
        height: 60%;
        bottom: 0px;
    }

    #books, #books-mirrored{
        position: absolute;
        width: 300px;
        transform: skewY(-$skew);
        svg {
            width: 100%;
        }
    }
    #books-mirrored {
        right: 0px;
        margin-top: -200px;
    }


    div.book-main{
        margin-bottom: 20px;
    }

    .book-details-content{
        margin: auto;
        max-width: $xl;
        h1{
            font-size: 48px;
            text-align: left;
        }
        .author-text{
            font-size: 24px;
            text-align: left;
        }
        .cover-container {
            margin-bottom: 0px;
            .BookImage {
                width: 100%;
                max-width: 250px;
            }
        }
        h2, h3 {
            font-size: 30px;
            margin-bottom: 16px;
        }
    }
}
